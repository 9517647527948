<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3 pl-1">
        <v-row>
          <v-col>
            <site-header
              :backRouteName="null"
              :backText="$_t('backText')"
              :title="$_t('title')"
              :sub-title="$_t('subTitle')"
              @backEvent="backMessage"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <v-container>
      <main class="pb-8">
        <div class="steps mt-13" ref="allSteps">
          <div v-for="(step, i) in steps" :ref="`step-${i}`" :key="i">
            <div class="steps__step--number">step {{ i + 1 }}</div>
            <div class="steps__step--title">{{ step.text }}</div>
          </div>
        </div>
        <v-progress-linear
          class="mt-3"
          :value="percentProgress"
          background-color="transparent"
          color="black"
          height="3"
        ></v-progress-linear>
        <list class="mt-3">
          <div class="steper">
              <component
                v-model="form"
                :isSetData="isSetData"
                @nextStep="nextStep"
                @prevStep="prevStep"
                @update="updateProfileModel"
                :is="currentTabComponent"
              ></component>
          </div>
        </list>
      </main>
    </v-container>
    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
  </div>
</template>
<script>
import List from "../components/Global/Section/List.vue";
import BasicInfo from "../components/Steps/BasicInfo.vue";
import ContactInfo from "../components/Steps/ContactInfo.vue";
import PhysicalInfo from "../components/Steps/PhysicalInfo.vue";
import FinalCheckInfo from "../components/Steps/FinalCheckInfo.vue";
import BankInfo from "../components/Steps/BankInfo.vue";
import UserRepository from "../abstraction/repository/userRepository";
import ModelRepository from "../abstraction/repository/modelRepository";
import { UserStorage } from "../utils/storage";
import { makeid } from "@/utils/math";
import toast from "@/utils/toast";
const RANDOM_TOKEN = makeid(50);
import {mapGetters, mapMutations} from "vuex";
export default {
  components: {
    BasicInfo,
    ContactInfo,
    FinalCheckInfo,
    PhysicalInfo,
    BankInfo,
    List,
  },
  data() {
    return {
      percentProgress: 0,
      currentTab: 0,
      steps: [
        { text: "Basic Info", component: "BasicInfo" },
        { text: "Contact Info", component: "ContactInfo" },
        { text: "Physical Info", component: "PhysicalInfo" },
        { text: "Bank & visa Info", component: "BankInfo" },
        { text: "Final check", component: "FinalCheckInfo" },
      ],
      form: {
        loadingData : true,
        thumbnail: "https://via.placeholder.com/200x200",
        languagesWithLevel: [{ level_id: null, id: null }],
        model: {},
        file_batch_id: RANDOM_TOKEN,
        dropzone_images : [],
        fileForm: {
          model_name: "model",
          collection_name: "main_image",
          batch_id: RANDOM_TOKEN,
        },
      },
      isSetData : false,
    };
  },
  computed: {
    ...mapGetters("user", ["getMyInfo"]),
    currentTabComponent() {
      return this.steps[this.currentTab].component;
    },
    isBasic() {
      if (this.currentTab !== "basic") {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapMutations('user', ['SET_MY_INFO']),
    backMessage() {
      toast.question(this.$_trans("toast.become_model_return_msg"), this.$_trans("toast.become_model_return_title"), () => {
        this.$router.push({
          name: "dashboard",
          params: { locale: this.$_getlocaleParam() },
        });
      });
    },
    nextStep() {
      this.currentTab++;
      this.changeWidth();
    },
    prevStep() {
      this.currentTab--;
      this.changeWidth();
    },
    async updateProfileModel(setLoading) {
      try{
          setLoading(true)
          let repository = new ModelRepository();
          let response = await repository.update(this.form);
          if (response) {
            UserStorage.save(response);
            this.SET_MY_INFO(response);
            this.$router.push({
              name: "requestSended",
              params: { locale: this.$_getlocaleParam() },
            });
          }
      }catch(e){
        console.log(e);
      }finally{
        setLoading(false)
      }
    },
    changeWidth() {
      if (this.$refs["step-" + this.currentTab]) {
        let blockWidth = this.$refs["step-" + this.currentTab][0].clientWidth;
        let steperWidth = this.$refs["allSteps"].clientWidth;
        this.percentProgress =
          (100 / this.steps.length) * this.currentTab + (100 * blockWidth) / steperWidth;
      }
    },
    async loadMyInfo() {
      this.form.loadingData = true;
      let repository = new UserRepository();
      let form = await repository.myInfo();
      this.form.loadingData = false;
      this.isSetData = true;
      if (form) {
        UserStorage.save(form);
      }
      if(!form.model_types.length) {
        form.model_types = [{id: 5,name: "Fashion",name_jpn: "ファッション"}]
      }
      if(form.model_id){
        this.$router.push({
          name: "requestSended",
          params: { locale: this.$_getlocaleParam() },
        });
      }
      this.form = { ...this.form, ...form };
    },
  },
  mounted() {
    this.changeWidth();
    this.loadMyInfo();
  },
};
</script>
<style scoped>
main {
  min-height: auto;
}
/* container */
.container {
  max-width: 1440px;
}
.steps {
  display: flex;
  justify-content: space-between;
  line-height: 20px;
}
.steps .steps__step--number {
  font-size: 14px;
  font-family: "Montserrat-regular";
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--color-smoky);
}
.steps .steps__step--title {
  font-size: 21px;
  letter-spacing: 3px;
}
.steper {
  padding: 30px;
}
@media screen and (max-width: 960px) {
  .steper {
    padding: 10px;
  }
  .steps {
    line-height: 15px;
  }
  .steps .steps__step--title {
    font-size: 17px;
    letter-spacing: 2px;
  }
  .steps .steps__step--number {
    font-size: 13px;
    letter-spacing: 2px;
  }
}
@media screen and (max-width: 600px) {
  .btn-next {
    padding-right: 12px !important;
    padding-left: 12px !important;
    font-size: 0.625rem !important;
  }
  .steps {
    line-height: 10px;
  }
  .steps .steps__step--title {
    font-size: 10px;
    letter-spacing: 0;
  }
  .steps .steps__step--number {
    font-size: 9px;
    letter-spacing: 2px;
  }
  .v-progress-linear {
    margin-top: 2px !important;
  }
}
</style>
